<template>
  <div :class="$style.location">
    <div :class="$style.wrapper">
      <el-button @click.prevent="addDomain"> Добавить новый полигон </el-button>
      <el-button size="small" type="primary" @click="submitForm">
        Создать
      </el-button>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="170px"
    >
      <el-form-item label="Название" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="Нижняя граница" prop="lowerPrice">
        <el-input v-model.number="form.lowerPrice" />
      </el-form-item>
      <el-form-item label="Верхняя граница" prop="higherPrice">
        <el-input v-model.number="form.higherPrice" />
      </el-form-item>
      <el-form-item label="По умолчанию">
        <el-checkbox v-model="form.isDefault" />
      </el-form-item>
      <Container
        :non-drag-area-selector="('input', 'textarea', '.drag-disabled')"
        tag="div"
        lock-axis="y"
        :class="$style.body"
        @drop="onDrop"
        @drag-start="drag = false"
        @drag-end="drag = true"
      >
        <Draggable
          v-for="(domain, index) in form.deliveryParams"
          :key="index"
          tag="div"
          :class="$style.row"
        >
          <DropdownSectionWrapper
            :is-open="domain.isOpen"
            :title="domain.name"
            @header-click="domain.isOpen = !domain.isOpen"
          >
            <el-form-item
              :key="index"
              :class="[$style.locationInfo, 'drag-disabled']"
            >
              <el-form-item
                :prop="'deliveryParams.' + index + '.name'"
                :rules="{
                  required: true,
                  message: 'Пожалуйста, заполните поле',
                  trigger: 'blur'
                }"
                label="Имя"
                label-width="15.625rem"
                :class="[$style.item, 'drag-disabled']"
              >
                <el-input v-model="domain.name" />
              </el-form-item>
              <el-form-item
                :prop="'deliveryParams.' + index + '.hint'"
                :rules="{
                  required: true,
                  message: 'Пожалуйста, заполните поле',
                  trigger: 'blur'
                }"
                label="Подсказка"
                label-width="15.625rem"
                :class="[$style.item, 'drag-disabled']"
              >
                <el-input v-model="domain.hint" />
              </el-form-item>
              <el-form-item
                :prop="'deliveryParams.' + index + '.minPrice'"
                :rules="{
                  type: 'number',
                  min: 1,
                  message: 'Цена должна быть больше 0',
                  trigger: 'blur'
                }"
                label="Минимальная цена"
                label-width="15.625rem"
                :class="[$style.item, 'drag-disabled']"
              >
                <el-input v-model.number="domain.minPrice" />
              </el-form-item>
              <el-form-item
                :prop="'deliveryParams.' + index + '.midPrice'"
                :rules="{
                  type: 'number',
                  min: 1,
                  message: 'Цена должна быть больше 0',
                  trigger: 'blur'
                }"
                label="Промежуточная цена"
                label-width="15.625rem"
                :class="[$style.item, 'drag-disabled']"
              >
                <el-input v-model.number="domain.midPrice" />
              </el-form-item>
              <el-form-item
                :prop="'deliveryParams.' + index + '.maxPrice'"
                :rules="{
                  type: 'number',
                  min: 1,
                  message: 'Цена должна быть больше 0',
                  trigger: 'blur'
                }"
                label="Максимальная цена"
                label-width="15.625rem"
                :class="[$style.item, 'drag-disabled']"
              >
                <el-input v-model.number="domain.maxPrice" />
              </el-form-item>
              <el-form-item label="Способы оплаты" label-width="15.625rem">
                <el-select
                  v-model="domain.paymentTypes"
                  placeholder="Выберите"
                  class="drag-disabled"
                  collapse-tags
                  multiple
                  clearable
                  :class="[$style.item, $style.select]"
                >
                  <el-option
                    v-for="item in paymentTypesList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :prop="
                  'deliveryParams.' + index + '.isAvailableExpressDelivery'
                "
                label="Доступен для срочной доставки"
                label-width="15.625rem"
                :class="$style.item"
              >
                <el-checkbox v-model="domain.isAvailableExpressDelivery">
                </el-checkbox>
              </el-form-item>
              <el-form-item
                :prop="'deliveryParams.' + index + '.expressDeliveryPrice'"
                :rules="{
                  type: 'number',
                  min: 0,
                  message: 'Цена должна быть больше либо равна 0',
                  trigger: 'blur'
                }"
                label="Цена срочной доставки"
                label-width="15.625rem"
                :class="[$style.item, 'drag-disabled']"
              >
                <el-input v-model.number="domain.expressDeliveryPrice" />
              </el-form-item>
              <div :class="$style.mapWrapper" class="drag-disabled">
                <MapPolygon
                  :class="$style.map"
                  :coordinates="domain.polygon"
                  @change="domain.polygon = $event"
                />
              </div>
              <el-button @click.prevent="removeDomain(index)"
                >Удалить</el-button
              >
            </el-form-item>
          </DropdownSectionWrapper>
        </Draggable>
      </Container>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'

import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'

import MapPolygon from '@/components/atoms/MapPolygon.vue'
import DropdownSectionWrapper from '@/components/moleculs/DropdownSectionWrapper.vue'

export default {
  components: { MapPolygon, DropdownSectionWrapper },
  mixins: [notifications],
  ADDWINE_ADMIN_ROUTES,
  watch: {
    async drag() {
      if (this.drag && this.isDropResult) {
        this.isDropResult = false
        this.updateOrders()
      }
    }
  },
  data() {
    return {
      paymentTypesList: [],
      drag: false,
      isDropResult: false,
      list: [],
      form: {
        name: '',
        isDefault: false,
        lowerPrice: 0,
        higherPrice: 0,
        deliveryParams: [
          {
            name: 'Локация 1',
            hint: '',
            minPrice: 0,
            midPrice: 0,
            maxPrice: 0,
            orderField: 0,
            polygon: '',
            isOpen: true
          }
        ]
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        lowerPrice: [
          {
            type: 'number',
            min: 1,
            message: 'Цена должна быть больше 0',
            trigger: 'blur'
          }
        ],
        higherPrice: [
          {
            type: 'number',
            min: 1,
            message: 'Цена должна быть больше 0',
            trigger: 'blur'
          }
        ],
        deliveryParams: {
          type: 'array',
          name: [
            {
              required: true,
              message: 'Заполните поле',
              trigger: 'blur'
            }
          ]
        }
      }
    }
  },
  async created() {
    await this.getPaymentTypes()
  },
  methods: {
    async getPaymentTypes() {
      const { value, error } =
        await delivery.PaymentServiceCore.PaymentTypesActions.getList()

      if (error) return

      this.paymentTypesList = value.data
    },
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })
          const obj = JSON.parse(JSON.stringify(this.form))
          obj.deliveryParams = obj.deliveryParams.map((item) => {
            delete item.isOpen
            item.polygon = JSON.parse(item.polygon)
            item.paymentTypes = item.paymentTypes?.map((paymentTypeId) => ({
              id: paymentTypeId
            }))
            return item
          })
          const result =
            await delivery.PaymentServiceCore.DeliveryPricesActions.create(obj)

          loading.close()

          if (result.error) {
            this.showNotification(
              'Ошибка сохранения локации. Перепроверьте заполненные данные. Возможно локация по умолчанию уже создана',
              'error'
            )
            return
          }

          this.form = result.value

          this.$router.push(
            ADDWINE_ADMIN_ROUTES.PAYMENTS.LOCATIONS_DELIVERY.INDEX
          )

          this.showNotification('Локация успешно создана', 'success')
        }
      })
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    updateOrders() {
      for (let i = 0; i < this.form.deliveryParams.length; i++) {
        this.form.deliveryParams[i].orderField = i + 1
      }
    },
    onDrop(dropResult) {
      document.body.classList.remove('smooth-dnd-no-user-select')
      document.body.classList.remove('smooth-dnd-disable-touch-action')
      this.form.deliveryParams = this.applyDrag(
        this.form.deliveryParams,
        dropResult
      )
      this.isDropResult = true
    },
    removeDomain(index) {
      this.form.deliveryParams.splice(index, 1)
    },
    addDomain() {
      this.form.deliveryParams.push({
        name: '',
        hint: '',
        minPrice: 0,
        midPrice: 0,
        maxPrice: 0,
        orderField: 0,
        polygon: '',
        isAvailableExpressDelivery: false,
        expressDeliveryPrice: 0,
        isOpen: true
      })
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.location {
  padding: 0 1rem 2rem;
  .wrapper {
    @include stickyWrapper;
  }
  .tag + .tag {
    margin-left: 0.625rem;
  }
  .buttonNewTag {
    margin-left: 0.625rem;
    height: 2rem;
    line-height: 1.875rem;
    padding-top: 0;
    padding-bottom: 0;
  }
  .inputNewTag {
    width: 16rem;
    margin-left: 0.625rem;
    vertical-align: bottom;
  }
  .locationInfo {
    border: 1px solid $black-gray;
    border-left: none;
    border-right: none;
    padding: 1rem 0;
    cursor: grab;
    .item {
      &.select {
        width: 100%;
      }
      margin-bottom: 1.5rem;
    }
  }
  .mapWrapper {
    max-width: 100%;
    min-width: 18.5rem;
    width: 100%;
    height: 40rem;
    margin: 1.5rem 0;
  }
  .prices {
    display: flex;
  }
  label {
    margin: 0;
  }
  .filterItem > label {
    width: auto !important;
  }
}
</style>

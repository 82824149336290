<template>
  <yandex-map
    :coords="coords"
    :map-type="maptype"
    zoom="8"
    @click="addPoint"
    @map-was-initialized="onInitMap"
  >
  </yandex-map>
</template>

<script>
import { yandexMap } from 'vue-yandex-maps'
export default {
  components: { yandexMap },
  name: 'AtomsMap',
  props: {
    coordinates: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      coords: [55.755864, 37.617698],
      maptype: 'map',
      polygons: [],
      map: null
    }
  },
  methods: {
    onInitMap(map) {
      this.map = map

      const coordinates = this.coordinates ? [JSON.parse(this.coordinates)] : []

      this.initializePolygon(coordinates)
    },
    addPoint() {
      if (this.coordinates) return

      this.initializePolygon([])
    },
    initializePolygon(coordinates) {
      const myPolygon = new window.ymaps.Polygon(
        coordinates,
        {},
        {
          editorDrawingCursor: 'crosshair',
          editorMaxPoints: 100,
          fillColor: '#11ffee00',
          strokeColor: '#0000FF',
          strokeWidth: 2
        }
      )

      this.map.geoObjects.add(myPolygon)

      const stateMonitor = new window.ymaps.Monitor(myPolygon.editor.state)
      stateMonitor.add('drawing', (newValue) => {
        myPolygon.options.set({
          strokeColor: newValue ? '#FF0000' : '#0000FF'
        })
      })

      myPolygon.geometry.events.add('change', (event) => {
        const newCoordinates = event.get('newCoordinates')
        if (!newCoordinates[0]?.length) {
          myPolygon.editor.startDrawing()
        }
      })

      !coordinates?.length || !coordinates[0]?.length
        ? myPolygon.editor.startDrawing()
        : myPolygon.editor.startEditing()

      myPolygon.events.add('geometrychange', (event) => {
        setTimeout(() => {
          const polygon = event.get('target').geometry.getCoordinates()
          this.$emit('change', JSON.stringify(polygon[0]))
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ymap-container {
  width: 100%;
  height: 100%;
}
</style>

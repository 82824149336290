<template>
  <div :class="$style.wrapper">
    <div :class="$style.header" @click="$emit('header-click', !isOpen)">
      <Icon name="draggable" :class="$style.draggable" @click.stop="" />
      <adw-icon
        name="arrow"
        :class="[$style.arrow, { [$style.hide]: !isOpen }]"
      />
      <span>{{ title }}</span>
    </div>
    <collapse-transition>
      <slot v-if="isOpen" />
    </collapse-transition>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon'
export default {
  name: 'DropdownSectionWrapper',
  components: { Icon },
  props: {
    isOpen: {
      type: Boolean,
      default: true
    },
    title: String
  }
}
</script>

<style lang="scss" module>
.wrapper {
  margin: 1rem auto;
  .header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    .draggable {
      cursor: move;
      width: 1rem;
      height: 1rem;
    }
    .arrow {
      transition: transform 0.3s ease-in-out;
      transform: rotate(0);
      &.hide {
        transform: rotate(-90deg);
      }
    }
  }
}
</style>
